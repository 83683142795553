import React, { useEffect, useState } from "react";
import api from "lib/api";
import PropTypes from "prop-types";
import parse from "html-react-parser";
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import * as OpenPositionsStyling from "./OpenPositions.module.scss";
import {graphql} from "gatsby";

const OpenPositions = ({ block }) => {
  const [jobsHTML, setJobsHTML] = useState(null);
  const title = block.title;
  const subtitle = block.subtitle;

  useEffect(() => {
    async function getOpenPositions() {
      const jobsHTML = await api.get(
        "https://soundtrackyourbrand.bamboohr.com/jobs/embed2.php?version=1.0.0&departmentId=0",
        false
      );
      setJobsHTML(jobsHTML);
      let links = document.querySelectorAll(
        `.${OpenPositionsStyling.openPositionsSection} a`
      );
      let styleEl = document.querySelector(
        `.${OpenPositionsStyling.openPositionsSection} style`
      );
      if (styleEl) {
        styleEl.parentNode.removeChild(styleEl);
      }
      links.forEach(function (value) {
        value.setAttribute("target", "_blank");
        value.setAttribute("rel", "noreferrer");
      });
    }
    getOpenPositions();
  }, []);

  return (
    <Section className={OpenPositionsStyling.openPositionsSection}>
      <div className="mb-7">
        <h2 className={`text48 ${OpenPositionsStyling.title}`}>{title}</h2>
        <h3 className="text5">{subtitle}</h3>
      </div>
      <div className={OpenPositionsStyling.positionsWrapper}>
        {jobsHTML && parse(jobsHTML)}
      </div>
    </Section>
  );
};

export default OpenPositions;

OpenPositions.propTypes = {
  block: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
  }),
};

export const query = graphql`
  fragment OpenPositions on DatoCmsOpenposition {
    model {
      apiKey
    }
    id: originalId
    title
    subtitle
  }
`